import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import medciarecard from "../../assets/icons/medicarecard.jpg";
import sealsIcon from "../../assets/icons/seals.png";
import "../../assets/styles/Form.scss";
import { sessionStorageKeys } from "../../constants/localStorage.js";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import { LEAD } from "../../constants/lead";

const RedWhiteBlueMedicare = () => {
  const [search] = useSearchParams();
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData, removeRingba } = useRgbaHook();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSelectedOption(
      sessionStorage.getItem(sessionStorageKeys.enrolledMedicare)
    );
  }, []);

  const navigate = useNavigate();

  const removeLeadScript = () => {
    const leadNode = window.document.getElementById(LEAD.id);
    if (leadNode) leadNode.remove();
    const leadWrapper = window.document.getElementById(LEAD.wrapperId);
    if (leadWrapper) leadWrapper.remove();
  };

  const incFormState = (value) => {
    sessionStorage.setItem(sessionStorageKeys.enrolledMedicare, value);

    storeRgbaData("enrolledMedicare", value);
    if (value === "yes") {
      removeLeadScript();
      sessionStorage.setItem(sessionStorageKeys.submitSuccessful, "true");
      removeRingba(ROUTES.short.children.congrats + generatorQuery.get());
    } else {
      navigate({
        pathname: ROUTES.short.children.enrolledMedicaid,
        search: generatorQuery.get(),
      });
    }
  };

  const blankEnter = (e) => {};
  return (
    <>
      <div className="formHolder">
        <form
          action="POST"
          onSubmit={blankEnter}
          className="form row-gap-30 flex-d-col"
        >
          <div className="form-card-holder flex-a-cen-j-cen row-gap-30 flex-d-col">
            <div className="form-completion">
              <div className="semi-bold font-16 color-accent-blue">
                50% Completed
              </div>
              <div className="form-completion-bar fifty-percent">
                <div className="loadingbar"></div>
              </div>
            </div>

            <div className="form-ques-card row-gap-30">
              <div className="form-img-icons">
                <img src={medciarecard} alt="" />
              </div>

              <h3 className="font-24 color-primary">
                Do you have the red, white, and blue Medicare card?
              </h3>
              <p className="small">
                <small>
                  Why we need this? We use this data to check if you qualify for
                  a subsidized plan.
                </small>
              </p>
              <div className="form-options row-gap-20 flex-d-col flex-a-cen">
                <div
                  onClick={() => {
                    incFormState("yes");
                  }}
                  className={`form-age-option font-24 color-primary ${
                    selectedOption === "yes" ? "form-age-option-selected" : ""
                  }`}
                >
                  Yes
                </div>
                <div
                  onClick={() => {
                    incFormState("no");
                  }}
                  className={`form-age-option font-24 color-primary ${
                    selectedOption === "no" ? "form-age-option-selected" : ""
                  }`}
                >
                  No
                </div>
              </div>

              <div className="ssl_seals">
                <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                <img src={sealsIcon} alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RedWhiteBlueMedicare;
