import React from "react";
import "./Navbar.scss";
import logo from "../../assets/logo.png";
import phoneicon from "../../assets/phone.svg";
import { useInitRingba } from "../../hooks/rgba";
import { useLocation } from "react-router-dom";
import { useEventID } from "../../hooks/useEventID";
import { useSearchParams } from "react-router-dom";
import TiktokPixel from "tiktok-pixel";

export const Navbar = () => {
  const location = useLocation();
  const eventID = useEventID();
  const { number, staticNumber } = useInitRingba();

  const [search] = useSearchParams();

  const handleCallEventClick = () => {
    const key = "Contact";
    if (search.get("utm_source") == "facebook") {
      window.fbcFunc &&
        window.fbcFunc("track", key, {
          eventID: eventID,
        });
    }
    if (search.get("utm_source") == 'tiktok') {
      TiktokPixel.track(key, {}, { eventID: eventID });
    }
  };
  return (
    <div className="navbar">
      <nav>
        <div className="logoholder">
          <div className="logo">
            <img src={logo} alt="logo" />
            <div className="sitename">
              <h1>{process.env.REACT_APP_WEB_NAME || "QualifyBenefits.co"}</h1>
              <h2>a non-government medicare site</h2>
            </div>
          </div>
        </div>
        {!location.pathname.includes("congrats") &&
        !location.pathname.includes("call") ? (
          <a
            onClick={handleCallEventClick}
            href={`tel:${number || staticNumber}`}
            className="funnel_call"
            id="form-end-contact"
          >
            <div className="phone-icon">
              <img src={phoneicon} alt="logo" />
            </div>
            <div className="phone-number-holder">
              <div className="text-phone-number">
                CALL TOLL-FREE M-F 8AM-8PM EST
              </div>
              <div className="toll-free-number-holder">
                <div className="available-blinker"></div>
                <div className="toll-free-number">
                  <span id="font-end-contact-number" className="font-bold">
                    {number || staticNumber}{" "}
                  </span>
                  <span className="font-semibold">(TTY 711)</span>
                </div>
              </div>
            </div>
          </a>
        ) : undefined}
      </nav>
    </div>
  );
};
