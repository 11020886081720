import { GOOGLE_NOSCRIPT, scriptObj } from "../constants/voluumScripts";

class InitNoScripts {
  constructor(){

    this.generateGoogleNoScriptTag(GOOGLE_NOSCRIPT);
    this.generateFacebookNoScriptTag();
    this.generateVoluumNoScriptTag();
  }

  generateGoogleNoScriptTag = (src) => {
    var noscript = document.createElement("noscript");
    var iframe = document.createElement("iframe");
    iframe.setAttribute("src", `${src}`);
    iframe.setAttribute("height", "0");
    iframe.setAttribute("width", "0");
    iframe.setAttribute("style", "display:none;visibility:hidden");
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  };

  generateFacebookNoScriptTag = () => {
  };

  generateVoluumNoScriptTag = () => {
  };
}

export default InitNoScripts
