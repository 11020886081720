const base = "/assets/scripts/";
const end = ".js";

const scripts = [
  {
    domain: "qualifyacanow.com",
    scriptUrl: base + "qualifyacanow" + end,
    volumNoScript: "https://track.qualifyacanow.com/d/.js?noscript=true&ourl=",
    httpEquiv: "delegate-ch",
    content:
      "sec-ch-ua https://track.qualifyacanow.com; sec-ch-ua-mobile https://track.qualifyacanow.com; sec-ch-ua-arch https://track.qualifyacanow.com; sec-ch-ua-model https://track.qualifyacanow.com; sec-ch-ua-platform https://track.qualifyacanow.com; sec-ch-ua-platform-version https://track.qualifyacanow.com; sec-ch-ua-bitness https://track.qualifyacanow.com; sec-ch-ua-full-version-list https://track.qualifyacanow.com; sec-ch-ua-full-version https://track.qualifyacanow.com",
    facebookNoScript:
      "https://www.facebook.com/tr?id=684500325922868&ev=PageView&noscript=1",
    googleNoScript: "https://www.googletagmanager.com/ns.html?id=GTM-MPCJVTW",
  },
  {
    domain: "myacaquote.com",
    scriptUrl: base + "myacaquote" + end,
    volumNoScript: "https://track.myacaquote.com/d/.js?noscript=true&ourl=",
    httpEquiv: "delegate-ch",
    content:
      "sec-ch-ua https://track.myacaquote.com; sec-ch-ua-mobile https://track.myacaquote.com; sec-ch-ua-arch https://track.myacaquote.com; sec-ch-ua-model https://track.myacaquote.com; sec-ch-ua-platform https://track.myacaquote.com; sec-ch-ua-platform-version https://track.myacaquote.com; sec-ch-ua-bitness https://track.myacaquote.com; sec-ch-ua-full-version-list https://track.myacaquote.com; sec-ch-ua-full-version https://track.myacaquote.com",
    facebookNoScript:
      "https://www.facebook.com/tr?id=487439593578874&ev=PageView&noscript=1",
    googleNoScript: "https://www.googletagmanager.com/ns.html?id=GTM-KT8XQWB",
  },
  {
    domain: "justhealthbenefits.com",
    scriptUrl: base + "justhealthbenefits" + end,
    volumNoScript:
      "https://track.justhealthbenefits.com/d/.js?noscript=true&ourl=",
    httpEquiv: "delegate-ch",
    content:
      "sec-ch-ua https://track.justhealthbenefits.com; sec-ch-ua-mobile https://track.justhealthbenefits.com; sec-ch-ua-arch https://track.justhealthbenefits.com; sec-ch-ua-model https://track.justhealthbenefits.com; sec-ch-ua-platform https://track.justhealthbenefits.com; sec-ch-ua-platform-version https://track.justhealthbenefits.com; sec-ch-ua-bitness https://track.justhealthbenefits.com; sec-ch-ua-full-version-list https://track.justhealthbenefits.com; sec-ch-ua-full-version https://track.justhealthbenefits.com",
    facebookNoScript:
      "https://www.facebook.com/tr?id=1558807927960387&ev=PageView&noscript=1",
    googleNoScript: "https://www.googletagmanager.com/ns.html?id=GTM-TRRWZN2",
  },
  {
    domain: "health-benefits.co",
    scriptUrl: base + "healthbenefits" + end,
    volumNoScript: "https://track.health-benefits.co/d/.js?noscript=true&ourl=",
    httpEquiv: "delegate-ch",
    content:
      "sec-ch-ua https://track.health-benefits.co; sec-ch-ua-mobile https://track.health-benefits.co; sec-ch-ua-arch https://track.health-benefits.co; sec-ch-ua-model https://track.health-benefits.co; sec-ch-ua-platform https://track.health-benefits.co; sec-ch-ua-platform-version https://track.health-benefits.co; sec-ch-ua-bitness https://track.health-benefits.co; sec-ch-ua-full-version-list https://track.health-benefits.co; sec-ch-ua-full-version https://track.health-benefits.co",
    facebookNoScript:
      "https://www.facebook.com/tr?id=1291684211590181&ev=PageView&noscript=1",
    googleNoScript: "https://www.googletagmanager.com/ns.html?id=GTM-NZ2PBFN",
  },
  {
    domain: "qualifyaca.com",
    scriptUrl: base + "qualifyaca" + end,
    volumNoScript: "https://track.qualifyaca.com/d/.js?noscript=true&ourl=",
    httpEquiv: "delegate-ch",
    content:
      "sec-ch-ua https://track.qualifyaca.com; sec-ch-ua-mobile https://track.qualifyaca.com; sec-ch-ua-arch https://track.qualifyaca.com; sec-ch-ua-model https://track.qualifyaca.com; sec-ch-ua-platform https://track.qualifyaca.com; sec-ch-ua-platform-version https://track.qualifyaca.com; sec-ch-ua-bitness https://track.qualifyaca.com; sec-ch-ua-full-version-list https://track.qualifyaca.com; sec-ch-ua-full-version https://track.qualifyaca.com",
    facebookNoScript:
      "https://www.facebook.com/tr?id=3630004413952856&ev=PageView&noscript=1",
    googleNoScript: "https://www.googletagmanager.com/ns.html?id=GTM-M8F8CW4",
  },
  {
    domain: "qualifyobamacare.com",
    scriptUrl: base + "qualifyobamacare" + end,
    volumNoScript:
      "https://track.qualifyobamacare.com/d/.js?noscript=true&ourl=",
    httpEquiv: "delegate-ch",
    content:
      "sec-ch-ua https://track.qualifyobamacare.com; sec-ch-ua-mobile https://track.qualifyobamacare.com; sec-ch-ua-arch https://track.qualifyobamacare.com; sec-ch-ua-model https://track.qualifyobamacare.com; sec-ch-ua-platform https://track.qualifyobamacare.com; sec-ch-ua-platform-version https://track.qualifyobamacare.com; sec-ch-ua-bitness https://track.qualifyobamacare.com; sec-ch-ua-full-version-list https://track.qualifyobamacare.com; sec-ch-ua-full-version https://track.qualifyobamacare.com",
    facebookNoScript:
      "https://www.facebook.com/tr?id=1291684211590181&ev=PageView&noscript=1",
    googleNoScript: "https://www.googletagmanager.com/ns.html?id=GTM-NZ2PBFN",
  },
  {
    domain: "futurehealthcenter.com",
    scriptUrl: base + "futurehealthcenter" + end,
    volumNoScript:
      "https://track.futurehealthcenter.com/d/.js?noscript=true&ourl=",
    httpEquiv: "delegate-ch",
    content:
      "sec-ch-ua https://track.futurehealthcenter.com; sec-ch-ua-mobile https://track.futurehealthcenter.com; sec-ch-ua-arch https://track.futurehealthcenter.com; sec-ch-ua-model https://track.futurehealthcenter.com; sec-ch-ua-platform https://track.futurehealthcenter.com; sec-ch-ua-platform-version https://track.futurehealthcenter.com; sec-ch-ua-bitness https://track.futurehealthcenter.com; sec-ch-ua-full-version-list https://track.futurehealthcenter.com; sec-ch-ua-full-version https://track.futurehealthcenter.com",
    facebookNoScript:
      "https://www.facebook.com/tr?id=1291684211590181&ev=PageView&noscript=1",
    googleNoScript: "https://www.googletagmanager.com/ns.html?id=GTM-NZ2PBFN",
  },
];

const findScript = scripts.find(
  (s) =>
    s.domain.toLowerCase() ===
    (process.env.REACT_APP_DOMAIN_NAME || "").toLowerCase()
);

let CALL_SCRIPT;
let SHORT_SCRIPT;
let FULL_SCRIPT;
let FACEBOOK_NOSCRIPT;
let GOOGLE_NOSCRIPT;
let scriptObj = {};

if (findScript) {
  CALL_SCRIPT = findScript.scriptUrl;
  SHORT_SCRIPT = findScript.scriptUrl;
  FULL_SCRIPT = findScript.scriptUrl;
  GOOGLE_NOSCRIPT = findScript.googleNoScript;
  FACEBOOK_NOSCRIPT = findScript.facebookNoScript;
  scriptObj = findScript;
}

export { GOOGLE_NOSCRIPT, FACEBOOK_NOSCRIPT, scriptObj };

export default {
  CALL_SCRIPT,
  FULL_SCRIPT,
  SHORT_SCRIPT,
  FACEBOOK_NOSCRIPT,
  GOOGLE_NOSCRIPT,
};
