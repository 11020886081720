import Cookies from "js-cookie";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { v4 as uuid } from "uuid";
import "./App.scss";
import { localStorageKeys, sessionStorageKeys } from "./constants/localStorage";
import { SLACK_WEBHOOK_URL } from "./constants/slackWebhook";
import { scriptObj } from "./constants/voluumScripts";
import InitNoScripts from "./hooks/noScripts";
import Navigator from "./route/navigator";

const path = localStorage.getItem(localStorageKeys.reloadRingbaScript);
if (localStorage.getItem(localStorageKeys.reloadRingbaScript)) {
  window.location.href = path;
  localStorage.removeItem(localStorageKeys.reloadRingbaScript);
}

new InitNoScripts();

const overrideConsoleOutputs = async (e) => {
  try {
    await fetch(SLACK_WEBHOOK_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },

      method: "POST",
      body: JSON.stringify({
        text: `( ${String(e)}) , ${String(window.location.href)}`,
      }),
    });
  } catch (error) { }
};

window.console.log = (e) => {};
window.console.error = (e) => {}
window.console.warn = (e) => {}
window.console.info = (e) => {};

// console.warn("ADA", )

function App() {
  const eventID = Cookies.get("eventID");
  if (eventID && eventID.length) {
    sessionStorage.setItem(sessionStorageKeys.eventID, eventID);
    Cookies.remove("eventID");
  }

  const placement = Cookies.get("placement");
  if (placement && placement.length) {
    sessionStorage.setItem(sessionStorageKeys.placement, placement);
    Cookies.remove("placement");
  }

  const grbaid = Cookies.get("grbaid");
  if (grbaid && grbaid.length) {
    sessionStorage.setItem(sessionStorageKeys.grbaid, grbaid);
    Cookies.remove("grbaid");
  }

  const wbraid = Cookies.get("wbraid");
  if (wbraid && wbraid.length) {
    sessionStorage.setItem(sessionStorageKeys.wbraid, wbraid);
    Cookies.remove("wbraid");
  }
  
  const gclid = Cookies.get("gclid");
  if (gclid && gclid.length) {
    sessionStorage.setItem(sessionStorageKeys.gclid, gclid);
    Cookies.remove("gclid");
  }

  const acc_id = Cookies.get("acc_id");
  if (acc_id && acc_id.length) {
    sessionStorage.setItem(sessionStorageKeys.acc_id, acc_id);
    Cookies.remove("acc_id");
  }

  const checkOrCreateVisitorId = () => {
    const landerVisitorId = Cookies.get("visitor_id");
    const vl_click_id = Cookies.get("vl_click_id");
    const localStorageVisitorId = localStorage.getItem(
      localStorageKeys.visitorId
    );

    localStorage.setItem(localStorageKeys.vl_click_id, vl_click_id);

    if (landerVisitorId && landerVisitorId.length > 0) {
      return localStorage.setItem(localStorageKeys.visitorId, landerVisitorId);
    }

    if (localStorageVisitorId) {
    } else {
      const newVisitorId = uuid();
      localStorage.setItem(localStorageKeys.visitorId, newVisitorId);
    }
  };

  useEffect(() => {
    window.document.title = `Apply Now - ${process.env.REACT_APP_WEB_NAME_FULL}`;
    checkOrCreateVisitorId();
  }, []);
  return (
    <HelmetProvider>
      <TrackHelmet />
      <div className="App">
        {!window.location.pathname.includes("congrats") &&
          !window.location.pathname.includes("short") &&
          !window.location.pathname.includes("call") ? (
          <input id="leadid_token" name="universal_leadid" type="hidden" />
        ) : undefined}
        <Navigator />
      </div>

      {!window.location.pathname.includes("congrats") &&
        !window.location.pathname.includes("short") &&
        !window.location.pathname.includes("call") ? (
        <LeadNoScript />
      ) : undefined}
    </HelmetProvider>
  );
}

function TrackHelmet() {
  return (
    <Helmet>
      <meta httpEquiv={scriptObj.httpEquiv} content={scriptObj.content} />
    </Helmet>
  );
}

function LeadNoScript() {
  return (
    <Helmet>
      <noscript>
        {
          '<img src="//create.leadid.com/noscript.gif?lac=E1DEE81A-527B-1485-290C-8AAFFDB8B593&lck=e4127813-533f-79a4-c481-7daca8b33bed&snippet_version=2" />'
        }
      </noscript>
    </Helmet>
  );
}

export default App;
