import axios from "axios";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import * as yup from "yup";
import errorimg from "../../assets/form/error.svg";
import next from "../../assets/form/next.svg";
import sealsIcon from "../../assets/icons/seals.png";
import zipcode from "../../assets/icons/002-location.svg";
import "../../assets/styles/Form.scss";
import { localStorageKeys, sessionStorageKeys } from "../../constants/localStorage";
import { REDIRECT_AND_STORAGE_KEYS } from "../../constants/queryStrings";
import { ROUTES } from "../../constants/routes";
import VoluumScripts from "../../constants/voluumScripts.js";
import { useRgbaHook } from "../../hooks/rgba";
import { useDataLayer } from "../../hooks/useDataLayer";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import { useEventID } from "../../hooks/useEventID";

const scriptId = "trackScript";
if ( VoluumScripts && VoluumScripts.FULL_SCRIPT && VoluumScripts.FULL_SCRIPT.length > 5) {
  const isExists = document.getElementById(scriptId);
  if (!isExists) {
    const doc = document.createElement("script");
    doc.src = VoluumScripts.FULL_SCRIPT;
    doc.id = scriptId;
    doc.async = false;
    window.document.body.appendChild(doc);
  }
}

const initialValues = {
  zip: "",
};

const validationSchema = yup.object({
  zip: yup
    .string()
    .required("Zip Code is Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Zip code should be of 5 digit.")
    .max(5, "Zip code should be of 5 digit."),
});

export default function ZipCodeForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRgbaHook();
  const dataLayer = useDataLayer();
  const [response, setResponse] = useState({});
  const [search] = useSearchParams();
  const [clickId, setClickId] = useState();
  const fbc = Cookies.get("_fbc") || "";
  const fbp = Cookies.get("_fbp") || "";
  const eventID = useEventID();

  const {
    handleSubmit,
    touched,
    setErrors,
    errors,
    setValues,
    values,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, event) => {
      if (!loading) {
        sessionStorage.setItem(sessionStorageKeys.zip, String(values.zip));
        incZipFormState(values.zip);
        storeRgbaData("zip", values.zip);

        storeRgbaData("user_agent", window.navigator.userAgent);

        storeRgbaData("fbc", fbc);
        storeRgbaData("fbp", fbp);

        dataLayer.set("state", response.state);
        dataLayer.set("city", response.city);
      }
    },
  });

  const incZipFormState = (zip) => {
    setLoading(true);
    setResponse({ city: "", state: "" });
    sessionStorage.setItem(
      sessionStorageKeys.zipCodeExtraValues,
      JSON.stringify({
        user_agent: navigator.userAgent,
        fbc: "",
        fbp: "",
        city: "",
        state: "",
        JornayaToken: "",
      })
    );
    axios
      .get("https://api.zippopotam.us/us/" + zip, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      })
      .then((response) => {
        const obj = response.data;
        const keys = Object.keys(obj);
        if (keys.length === 0) return setErrors({ zip: "Zip Code not valid" });
        setResponse({
          city: response.data["places"][0]["place name"],
          state: response.data["places"][0]["state abbreviation"],
        });
        storeRgbaData("city", response.data["places"][0]["place name"]);
        storeRgbaData(
          "state",
          response.data["places"][0]["state abbreviation"]
        );
        sessionStorage.setItem(
          sessionStorageKeys.zipCodeExtraValues,
          JSON.stringify({
            user_agent: navigator.userAgent,
            fbc: fbc,
            fbp: fbp,
            city: response.data["places"][0]["place name"],
            state: response.data["places"][0]["state abbreviation"],
            JornayaToken: "",
          })
        );
        navigate({
          pathname: ROUTES.short.children.employmentStatus,
          search: generatorQuery.get(),
        });
        setLoading(false);
      })
      .catch((error) => {
        setErrors({ zip: "Zip Code not valid" });
        setLoading(false);
        console.error("Zip Code " + error);
      });
  };

  const onChangeZipValue = (e) => {
    const value = e.target.value;
    const obj = {
      target: {
        name: "zip",
        value: String(value).slice(0, 5),
      },
    };
    dataLayer.set("zip", value);
    handleChange(obj);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    sessionStorage.setItem(sessionStorageKeys.submitSuccessful, "true" )
    const zipInitialValue = sessionStorage.getItem(sessionStorageKeys.zip);
    if (zipInitialValue) setValues({ zip: Number(zipInitialValue) });
  }, []);

  const pushInitialData = (userIp) => {
    localStorage.removeItem(sessionStorageKeys.ringbaData);
    const redirectQueries = {
      userIp,
    };

    storeRgbaData("acc_id", sessionStorage.getItem(sessionStorageKeys.acc_id));

    REDIRECT_AND_STORAGE_KEYS.forEach((obj) => {
      redirectQueries[obj.storageKey] = search.get(obj.redirectString) || "";
      storeRgbaData(obj.ringbaKey, search.get(obj.redirectString));
    });
    storeRgbaData("userIp", userIp);

    sessionStorage.setItem(
      sessionStorageKeys.utm_fbclid,
      JSON.stringify(redirectQueries)
    );

    for (const entry of search.entries()) {
      generatorQuery.set(entry[0], entry[1]);
    }

    storeRgbaData("fbc", fbc);
    storeRgbaData("fbp", fbp);

    storeRgbaData('fbPixelId', process.env.REACT_APP_FACEBOOK_PIXEL);
    storeRgbaData('domainName', process.env.REACT_APP_DOMAIN_NAME);

    storeRgbaData(
      "visitor_id",
      localStorage.getItem(localStorageKeys.visitorId)
    );

    storeRgbaData('event_id', eventID)
    const gclid = sessionStorage.getItem(sessionStorageKeys.gclid)
    if(gclid && gclid.length > 0){
      storeRgbaData("gclid", gclid);
    }

    const acc_id = sessionStorage.getItem(sessionStorageKeys.acc_id) 
    if(acc_id && acc_id.length > 0){
      storeRgbaData("acc_id", acc_id);
    }

    const placement = sessionStorage.getItem(sessionStorageKeys.placement) 
    if(placement && placement.length > 0){
      storeRgbaData("placement", placement);
    }

    const grbaid = sessionStorage.getItem(sessionStorageKeys.grbaid)
    if(grbaid && grbaid.length > 0){
      storeRgbaData("grbaid", grbaid);
    }

    const wbraid = sessionStorage.getItem(sessionStorageKeys.wbraid) 
    if(wbraid && wbraid.length > 0){
      storeRgbaData("wbraid", wbraid);
    }
  };

  const getIpAdd = async () => {
    let userIp;
    try {
      var response;
      response = await axios.get("https://api.ipify.org/?format=json", {
        method: "GET",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      userIp = response.data["ip"];
    } catch (error) {
      console.error("IpError" + error);
    }
    pushInitialData(userIp);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getIpAdd();
  }, []);

  useEffect(() => {
    if (clickId) {
      const vl_click_Id = localStorage.getItem(localStorageKeys.vl_click_id);
      storeRgbaData(
        "vl_click_id",
        vl_click_Id && vl_click_Id.length ? vl_click_Id : clickId
      );
      localStorage.setItem(localStorageKeys.vl_click_id, vl_click_Id);
    }
  }, [clickId]);

  return (
    <>

      {!clickId ? (
        <GetClickId clickId={clickId} setClickId={setClickId} />
      ) : undefined}

      <div className="formHolder">
        <div className="form row-gap-30 flex-d-col">
          <div className="form-card-holder flex-a-cen-j-cen row-gap-30 flex-d-col">
            <div className="form-completion">
              <div className="semi-bold font-16 color-accent-blue">
                10% Completed
              </div>
              <div className="form-completion-bar ten-percent">
                <div className="loadingbar"></div>
              </div>
            </div>
            <div className="form-ques-card row-gap-30">
              <div className="form-img-icons">
                <img src={zipcode} alt="" />
              </div>
              <h3 className="font-24 color-primary">What's your Zip Code?</h3>
              <p className="small">
                <small>
                  Why we need this? We use this data to check if you qualify for
                  a subsidized plan.
                </small>
              </p>
              <div className="form-options row-gap-30 flex-d-col">
                <form onSubmit={handleSubmit}>
                  <input
                    value={values.zip}
                    required
                    onChange={onChangeZipValue}
                    onBlur={handleBlur}
                    maxLength={5}
                    max={99999}
                    type="number"
                    name="zip"
                    id="zip"
                    placeholder="Zip Code"
                  />
                  {errors.zip && touched.zip ? (
                    <div className="form-error font-12 form-error-2">
                      <img src={errorimg} alt="" /> &nbsp;&nbsp; {errors.zip}
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    disabled={errors.zip}
                    className="form-button form-option-continue color-white font-20 bold"
                  >
                    {loading ? (
                      <>
                        <PropagateLoader
                          color="#07f2a4"
                          className="margin-loader"
                        />{" "}
                        <p className="visibility-hidden">.</p>{" "}
                      </>
                    ) : (
                      <>
                        Continue <img src={next} alt="" />
                      </>
                    )}
                  </button>
                </form>
              </div>

              <div className="ssl_seals">
                <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                <img src={sealsIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function GetClickId(props) {
  React.useEffect(() => {
    if (!props.clickId) {
      const interval = setInterval(() => {
        props.setClickId(sessionStorage.getItem("clickId"));
      }, 400);
      return () => clearInterval(interval);
    }
  }, []);
  return <></>;
}
