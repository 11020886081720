export const localStorageKeys = {
    visitorId: "visitorId",
    lastSubmittedData: "lastSubmittedData",
    vl_click_id: "vl_click_id",
    reloadRingbaScript: "reloadRingbaScript"
}

export const sessionStorageKeys = {
    zip: "zip",
    employmentStatus:"employmentStatus",

    grbaid:"grbaid",
    wbraid:"wbraid",

    ageAbove64: "ageAbove64",
    firstName: "firstName",
    lastName: "lastName",
    homePhone: "homePhone",
    email: "email",
    noOfPeoples: "noOfPeoples",
    income:"income",
    income2:"income2",
    enrolledMedicare:"enrolledMedicaid",
    enrolledMedicaid:"enrolledMedicare",

    zipCodeExtraValues: "zipCodeExtraValues",
    utm_fbclid: "utm_fbclid",

    submitSuccessful: "submitSuccessful",
    finalPreparedData: 'finalPreparedData',

    rgba:"rgba",
    generatorName: "generatorName", // remove at end
    generator: "generator",
    dataLayer: "dataLayer",

    medicareMedicaid: "medicareMedicaid",
    medicareAandB:"medicareAandB",
    clickId: "clickId",
    leadScriptInjected: "leadScriptInjected",
    
    isClickIdSent: "isClickIdSent",
    ringbaData: "ringbaData",

    eventID:"eventID",
    acc_id:"acc_id",
    gclid: "gclid",
    placement: "placement",
}