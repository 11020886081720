import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TiktokPixel from 'tiktok-pixel';

const advancedMatching = {};

const options = {
  debug: false,
};

var queryString = window.location.search;
var params = new URLSearchParams(queryString);
var utm_source = params.get('utm_source');

if(utm_source === 'tiktok'){
  TiktokPixel.init(process.env.REACT_APP_TIKTOK_ID || "", advancedMatching, options);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
