import React, { useEffect } from "react";
import "./Footer.scss";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useEventID } from "../../hooks/useEventID";
import { useSearchParams } from "react-router-dom";
import TiktokPixel from "tiktok-pixel";

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-holder">
          <div className="logoholder">
            <div className="logo">
              <img src={logo} alt="logo" />
              <div className="sitename">
                <h1>
                  {process.env.REACT_APP_WEB_NAME || "QualifyBenefits.co"}
                </h1>
                <h2>a non-government medicare site</h2>
              </div>
            </div>
          </div>
          <div className="footer-horizontal-divider"></div>
          <div className="font-13 color-dark-grey">
            Disclaimer: {process.env.REACT_APP_WEB_NAME || "QualifyBenefits.co"}{" "}
            is not an insurance or operating company but connects individuals
            with insurance providers and other affiliates. Plans are insured or
            covered by a Medicare Advantage organization with a Medicare
            contract and/or a Medicare-approved Part D sponsor. Enrollment in
            the plan depends on the plan’s contract renewal with Medicare.
            Possible options include, but are not limited to Major Medical
            Plans, Short Term Plans, Christian Health Plans, Health Sharing
            Plans, discount cards and Fixed Indemnity Plans. Descriptions are
            for informational purposes only and subject to change.{" "}
            {process.env.REACT_APP_WEB_NAME || "QualifyBenefits.co"} is not
            affiliated with any insurance plan nor does it represent or endorse
            any plan. We do not offer every plan available in your area. Any
            information we provide is limited to those plans we do offer in your
            area. Please contact Medicare.gov or 1–800 MEDICARE to get
            information on all of your options. By using this site, you
            acknowledge that you have read and agree to the{" "}
            <Link target={"_blank"} to={ROUTES.terms}>
              Terms and Conditions
            </Link>
            , and{" "}
            <Link target={"_blank"} to={ROUTES.privacyPolicy}>
              Privacy Policy
            </Link>
            . Not affiliated with the U. S. government or federal Medicare
            program. A licensed agent may contact you regarding this
            insurance-related information.
          </div>
          <div className="footer-links color-dark-grey">
            <div className="font-13">
              Copyright © 2023 | {process.env.REACT_APP_WEB_NAME_FULL}
            </div>
            <div className="footer-vertical-divider"></div>
            <div className="font-13">
              <Link target={"_blank"} to={ROUTES.privacyPolicy}>
                PRIVACY POLICY
              </Link>
            </div>
            <div className="footer-vertical-divider"></div>
            <div className="font-13">
              <Link target={"_blank"} to={ROUTES.terms}>
                TERMS OF USE
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AddEventId />
    </>
  );
};

function AddEventId() {
  const eventID = useEventID();
  const viewContentKey = "ViewContent";
  const viewContentKey2= "PageView2";
  const [search] = useSearchParams();

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.fbcFunc && !sessionStorage.getItem(viewContentKey)) {
        window.fbcFunc("track", viewContentKey, { eventID: eventID });
        sessionStorage.setItem(viewContentKey, "true");
      }
      
      if(search.get('utm_source') == 'tiktok' && !sessionStorage.getItem(viewContentKey2)){
        TiktokPixel.track(viewContentKey, {}, { eventID: eventID });
        sessionStorage.setItem(viewContentKey2, "true");
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, 20000);

    return () => clearInterval(interval);
  }, []);
  return <></>;
}
