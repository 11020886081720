import React, { useEffect, useRef, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import agent from "../../assets/form/agent.svg";
import call from "../../assets/form/call.svg";
import check from "../../assets/form/check.svg";
import checkpoint from "../../assets/form/checkpoint.svg";
import list from "../../assets/form/list.svg";
import location from "../../assets/form/location.svg";
import reload from "../../assets/form/reload.svg";
import "../../assets/styles/Congrats.scss";
import { FloatingCard } from "../../components/FloatingCard/FloatingCard";
import { LEAD } from "../../constants/lead";
import { sessionStorageKeys } from "../../constants/localStorage";
import { useRingbaUser } from "../../constants/ringba";
import { ROUTES } from "../../constants/routes";
import { useInitRingba } from "../../hooks/rgba";
import { useDataLayer } from "../../hooks/useDataLayer";
import { useEventID } from "../../hooks/useEventID";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import { FormStart } from "../FormStart/FormStart";
import TiktokPixel from "tiktok-pixel";

const PAGE_TITLE = `Congratulations ACA Recipient - ${process.env.REACT_APP_WEB_NAME}`;

if (!sessionStorage.getItem("reloaded")) {
  sessionStorage.setItem("reloaded", "true");
  window.location.reload();
}

const Congrats = () => {
  const { number: num } = useInitRingba();
  const [zipCodeData, setZipCodeData] = useState({ state: "", city: "" });

  useEffect(() => {
    const zipCodeExtraValues = sessionStorage.getItem(
      sessionStorageKeys.zipCodeExtraValues
    );
    if (zipCodeExtraValues) {
      const parsed = JSON.parse(zipCodeExtraValues);
      setZipCodeData(parsed);
    }
  }, []);

  return (
    <div className="flex-d-col form-end-congrats">
      <div className="font-32 color-accent-blue congrats-headline">
        Congratulations, We’ve Found Plans in your Area!
      </div>
      <div className="congrats-card font-16">
        <div className="flex-a-cen keymain">
          <div className="flex-a-cen">
            <img src={location} alt="" /> &nbsp;&nbsp; {zipCodeData["city"]},
            {zipCodeData["state"]}
          </div>

          <img src={check} alt="" />
        </div>

        <div className="flex-a-cen keymain">
          <div className="flex-a-cen">
            <img src={list} alt="" /> &nbsp;&nbsp;{" "}
            <p>Aetna | Blue Cross | Humana | United | Other</p>
          </div>

          <img src={check} alt="" />
        </div>

        <div className="flex-a-cen keymain">
          <div className="flex-a-cen">
            <img src={checkpoint} alt="" /> &nbsp;&nbsp;{" "}
            <p>New Medicare 2023 Plans</p>
          </div>

          <img src={check} alt="" />
        </div>

        <div className="flex-a-cen keymain">
          <div className="flex-a-cen">
            <img src={agent} alt="" /> &nbsp;&nbsp;{" "}
            <p>Connecting you with a licensed agent...</p>
          </div>

          <img className="reload" src={reload} alt="" />
        </div>
      </div>
    </div>
  );
};

const End = ({ fname, lname, staticNumber }) => {
  const eventID = useEventID();
  const [search] = useSearchParams();
  const handleCallEventClick = () => {
    if (search.get("utm_source") == "facebook") {
      window.fbcFunc &&
        window.fbcFunc("track", "Contact", {
          eventID: eventID,
        });
    }

    if (search.get("utm_source") == "tiktok") {
      TiktokPixel.track("Contact", {}, { eventID: eventID });
    }
  };

  useEffect(() => {
    const data = localStorage.getItem(sessionStorageKeys.ringbaData);
    if (data) {
      const parse = JSON.parse(data);
      const newSet = new Set();
      parse.forEach((i) => newSet.add(JSON.stringify(i)));
      const data2 = [...newSet].map((i) => JSON.parse(i));
      data2.forEach((i) => {
        window._rgba_tags = window._rgba_tags || [];
        window._rgba_tags.push(i);
      });
      // localStorage.removeItem(sessionStorageKeys.ringbaData);
    }
  }, []);
  return (
    <div className="form-end">
      <div className="congrats-message">
        <div className="font-32 bold color-accent-blue congrats-headline-cta">
          Great News!
        </div>
        <div className="font-16 row-gap-15 color-primary flex-d-col congrats-text">
          <p>
            You pre-qualify for{" "}
            <span>
              FREE $500 benefit card with select plans and $0/Month Health
              Insurance
            </span>{" "}
            With Free Healthcare that covers Medical Expenses.
          </p>
        </div>
        <a
          href={`tel:${window.pnumber || staticNumber}`}
          id="form-end-contact"
          className="form-end-contact"
          onClick={handleCallEventClick}
        >
          <div className="contact-btn">
            <img src={call} alt="" />
            <div className="color-white font-24 bold toll-free">
              <span id="font-end-contact-number">
                {window.pnumber || staticNumber}
              </span>
              <div className="tfn-cta">Click To Call Toll-Free</div>
            </div>
          </div>
        </a>
        <div className="font-16 color-primary congrats-deadline">
          <span className="uppercase bold">Your application expires in</span>{" "}
          <span className="bold">
            <Timer /> minute.
          </span>
        </div>
      </div>
    </div>
  );
};

const ACACongrats = ({ fname, lname }) => {
  fname = sessionStorage.getItem(sessionStorageKeys.firstName);
  lname = sessionStorage.getItem(sessionStorageKeys.lastName);

  const dataLayer = useDataLayer();
  const [history] = useSearchParams();
  const [submitted, setSubmitted] = useState();
  const generatorQuery = useGeneratorQuery();
  const [search] = useSearchParams();
  const [totalTime] = useState(180);
  const [loaderFinish, setLoaderFinish] = useState(false);

  const replaceExtraString = (str) => {
    try {
      const arr = str.split("&");
      const generator = arr.find((a) => a.includes("generator="));
      str = generator;
      if (str.includes("?generator=")) str = str.replace("?generator=", "");
      if (str.includes("generator=")) str = str.replace("generator=", "");
    } catch (error) {
      return history.get("generator");
    }
    return str;
  };

  const ringbaKey = useRingbaUser();
  const { number: num } = useInitRingba();

  const leadNode = window.document.getElementById(LEAD.id);

  const removeLeadScript = () => {
    if (leadNode) leadNode.remove();
    const leadWrapper = window.document.getElementById(LEAD.wrapperId);
    if (leadWrapper) leadWrapper.remove();
  };

  const checkPreviousPage = () => {
    const submitted = sessionStorage.getItem(
      sessionStorageKeys.submitSuccessful
    );
    setSubmitted(submitted);
    if (!submitted) {
      for (const entry of search.entries()) {
        generatorQuery.set(entry[0], entry[1]);
      }
    }
  };

  const addDataLayerAndQuery = () => {};

  useEffect(() => {
    window.document.title = PAGE_TITLE;
    addDataLayerAndQuery();

    removeLeadScript();
    checkPreviousPage();
  }, []);

  useEffect(() => {
    removeLeadScript();
  }, [leadNode]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const loader = setTimeout(() => {
      setLoaderFinish(true);
    }, 5000);

    return () => {
      clearTimeout(loader);
    };
  }, []);

  return (
    <div>
      {submitted === null ? (
        <Navigate
          to={{
            pathname: ROUTES.short.children.homePage,
            search: generatorQuery.get(),
          }}
        />
      ) : (
        <></>
      )}
      {loaderFinish ? (
        <End
          number={num}
          staticNumber={ringbaKey.number}
          fname={fname}
          lname={lname}
          min={(totalTime % 60) * 60}
          sec={totalTime % 60}
        />
      ) : (
        <Congrats fname={fname} lname={lname} />
      )}
      <FloatingCard />
      <FormStart />
    </div>
  );
};

const Timer = () => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:10");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 290);
    return deadline;
  };
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return <span>{timer}</span>;
};

export default ACACongrats;
