import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sealsIcon from "../../assets/icons/seals.png";
import incomeicon from "../../assets/icons/005-accounting.svg";
import "../../assets/styles/Form.scss";
import { sessionStorageKeys } from "../../constants/localStorage.js";
import { getIncome } from "../../constants/noOfPeoplesAndIncome";
import { ROUTES } from "../../constants/routes";
import { useRgbaHook } from "../../hooks/rgba";
import { useGeneratorQuery } from "../../hooks/useGeneratorQuery";
import { LEAD } from "../../constants/lead";

const Income = () => {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData, removeRingba } = useRgbaHook();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSelectedOption(sessionStorage.getItem(sessionStorageKeys.income));
  }, []);

  const navigate = useNavigate();

  const removeLeadScript = () => {
    const leadNode = window.document.getElementById(LEAD.id);
    if (leadNode) leadNode.remove();
    const leadWrapper = window.document.getElementById(LEAD.wrapperId);
    if (leadWrapper) leadWrapper.remove();
  };

  const handleSelection = (i) => {
    if (i.toLowerCase() === "yes") {
      storeRgbaData("income", "- - $50000");
      sessionStorage.setItem(sessionStorageKeys.income, String("- - $50000"));
    } else {
      storeRgbaData("income", "$50000 - +");
      sessionStorage.setItem(sessionStorageKeys.income, String("$50000 - +"));
    }
    removeLeadScript();
    sessionStorage.setItem(sessionStorageKeys.income2, String(i));
    if (i === "yes") {
      sessionStorage.setItem(sessionStorageKeys.submitSuccessful, "yes");
      removeRingba(ROUTES.short.children.acaCongrats + generatorQuery.get());
    } else {
      navigate({
        pathname: ROUTES.short.children.enrolledMedicare,
        search: generatorQuery.get(),
      });
    }
  };

  const blankEnter = (e) => {};

  return (
    <>
      <div className="formHolder">
        <form
          action="POST"
          onSubmit={blankEnter}
          className="form row-gap-30 flex-d-col"
        >
          <div className="form-card-holder flex-a-cen-j-cen row-gap-30 flex-d-col">
            <div className="form-completion">
              <div className="semi-bold font-16 color-accent-blue">
                40% Completed
              </div>
              <div className="form-completion-bar eighty-percent">
                <div className="loadingbar"></div>
              </div>
            </div>

            <div className="form-ques-card row-gap-16">
              <div className="form-img-icons">
                <img src={incomeicon} alt="" />
              </div>
              <div>
                <h3 className="font-24 color-primary">
                  Do you make under $50,000 per year?
                </h3>
                <p className="small">
                  <small>
                    Why we need this? We use this data to check if you qualify
                    for a subsidized plan.
                  </small>
                </p>
              </div>
              <div className="form-options flex-d-col row-gap-20 flex-a-cen">
                <div
                  onClick={() => handleSelection("yes")}
                  className={`form-age-option font-24 color-primary ${
                    selectedOption === "yes" ? "form-age-option-selected" : ""
                  }`}
                >
                  {"Yes"}
                </div>
                <div
                  onClick={() => handleSelection("no")}
                  className={`form-age-option font-24 color-primary ${
                    selectedOption === "no" ? "form-age-option-selected" : ""
                  }`}
                >
                  {"No"}
                </div>
              </div>
              <div className="ssl_seals margin-top-1rem">
                <p>WE USE 256 BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA.</p>
                <img src={sealsIcon} alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Income;
