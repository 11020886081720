import { MODULE_TYPE } from "./moduleType";

const generateRoutes = (routes) => {
  let str = "";
  routes.forEach((route) => (str += `/${route}`));
  return str;
};

export const ROUTES = {
  landing1: "/lander-1",
  landing2: "/lander-2",
  privacyPolicy: "/privacy-policy",
  terms: "/terms",
  partner: "/partner-list",

  full: {
    route: generateRoutes([MODULE_TYPE.full]),
    children: {
      homePage: generateRoutes([MODULE_TYPE.full, ""]),
      employmentStatus: generateRoutes([MODULE_TYPE.full, "employment-status"]),
      enrolledMedicare: generateRoutes([MODULE_TYPE.full, "medicare"]),
      enrolledMedicaid: generateRoutes([MODULE_TYPE.full, "medicaid"]),
      income: generateRoutes([MODULE_TYPE.full, "income"]),
      nameForm: generateRoutes([MODULE_TYPE.full, "name"]),
      phoneEmailForm: generateRoutes([MODULE_TYPE.full, "phone-mail"]),
      congrats: generateRoutes([MODULE_TYPE.full, "medicare-congrats"]),
      dnspCongrats: generateRoutes([MODULE_TYPE.full, "dnsp-congrats"]),
      acaCongrats: generateRoutes([MODULE_TYPE.full, "aca-congrats"]),
    },
  },
  short: {
    route: generateRoutes([MODULE_TYPE.short]),
    children: {
      homePage: generateRoutes([MODULE_TYPE.short, ""]),
      employmentStatus: generateRoutes([MODULE_TYPE.short, "employment-status"]),
      enrolledMedicare: generateRoutes([MODULE_TYPE.short, "medicare"]),
      enrolledMedicaid: generateRoutes([MODULE_TYPE.short, "medicaid"]),
      income: generateRoutes([MODULE_TYPE.short, "income"]),
      congrats: generateRoutes([MODULE_TYPE.short, "medicare-congrats"]),
      dnspCongrats: generateRoutes([MODULE_TYPE.short, "dnsp-congrats"]),
      acaCongrats: generateRoutes([MODULE_TYPE.short, "aca-congrats"]),
    },
  },
  call: {
    route: generateRoutes([MODULE_TYPE.call]),
    children: {
      homePage: generateRoutes([MODULE_TYPE.call, ""]),
    },
  },
};
