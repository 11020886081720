import React, { useEffect } from "react";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import "../assets/styles/Navigator.scss";
import { Footer } from "../components/Footer/Footer";
import { Navbar } from "../components/Navbar/Navbar";
import { MODULE_TYPE } from "../constants/moduleType";
import { ROUTES } from "../constants/routes";
import TypeShortCongrats from "../modules/short/Congrats";
import TypeShortDNSPCongrats from "../modules/short/DNSPCongrats";
import TypeShortACACongrats from "../modules/short/ACACongrats";
import TypeShortZipCodeForm from "../modules/short/ZipCodeForm";
import TypeShortWhiteBlue from "../modules/short/RedWhiteBlueCard";
import TypeShortMedicAid from "../modules/short/EnrolledMedicaid";
import TypeShortIncome from "../modules/short/Income";
import TypeShortEmploymentStatus from "../modules/short/EmplymentStatusForm";
// import TypeShortAgeAbove from "../modules/short/AgeForm"

const TypeFullEmploymentStatus = React.lazy(() => import("../modules/full/EmplymentStatusForm"));
const TypeFullMedicare = React.lazy(() =>
  import("../modules/full/RedWhiteBlueCard")
);
const TypeFullMedicaid = React.lazy(() =>
  import("../modules/full/EnrolledMedicaid")
);
const TypeFullIncome = React.lazy(() => import("../modules/full/Income"));
const TypeFullDNSPCongrats = React.lazy(() =>
  import("../modules/full/DNSPCongrats")
);
const TypeFullACACongrats = React.lazy(() =>
  import("../modules/full/ACACongrats")
);
const TypeFullCongrats = React.lazy(() => import("../modules/full/Congrats"));
const TypeFullZipCodeForm = React.lazy(() =>
  import("../modules/full/ZipCodeForm")
);
const TypeFullNameForm = React.lazy(() => import("../modules/full/NameForm"));
const TypeFullPhoneEmailForm = React.lazy(() =>
  import("../modules/full/PhoneEmailForm")
);

const PrivacyPolicy = React.lazy(() =>
  import("../modules/staticPages/privacyPolicy")
);
const Terms = React.lazy(() =>
  import("../modules/staticPages/termsAndConditions")
);
const Partners = React.lazy(() => import("../modules/staticPages/partners"));

const TypeCallCongrats = React.lazy(() => import("../modules/call/Congrats"));

const Loading = () => {
  return (
    <div className="centered-container">
      <PropagateLoader color="#2DA9C2" />
    </div>
  );
};

const ModuleTypeNavigator = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const navigateToModules = () => {
    let queryStr = "?";
    for (const entry of queryParams.entries()) {
      queryStr += `${entry[0]}=${entry[1]}&`;
    }

    const type = queryParams.get("type");
    switch (type) {
      case MODULE_TYPE.full:
        navigate(ROUTES.full.route + queryStr);
        break;
      case MODULE_TYPE.short:
        navigate(ROUTES.short.route + queryStr);
        break;
      case MODULE_TYPE.call:
        navigate(ROUTES.call.route + queryStr);
        break;
      default:
        navigate(ROUTES.full.route + queryStr);
        break;
    }
  };

  useEffect(() => {
    navigateToModules();
  }, []);

  if (!queryParams.get("type")) return null;
  return <Loading />;
};

const Navigator = () => {
  return (
    <div className="app-start">
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path={ROUTES.full.route} element={<Outlet />}>
            <Route
              index
              path={ROUTES.full.children.homePage}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullZipCodeForm />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.employmentStatus}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullEmploymentStatus />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.enrolledMedicare}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullMedicare />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.enrolledMedicaid}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullMedicaid />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.income}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullIncome />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.nameForm}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullNameForm />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.phoneEmailForm}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullPhoneEmailForm />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.congrats}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullCongrats />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.dnspCongrats}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullDNSPCongrats />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.full.children.acaCongrats}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeFullACACongrats />
                </React.Suspense>
              }
            />
          </Route>
          <Route path={ROUTES.short.route} element={<Outlet />}>
            <Route
              index
              path={ROUTES.short.children.homePage}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortZipCodeForm />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.short.children.employmentStatus}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortEmploymentStatus />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.short.children.enrolledMedicare}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortWhiteBlue />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.short.children.enrolledMedicaid}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortMedicAid />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.short.children.income}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortIncome />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.short.children.congrats}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortCongrats />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.short.children.dnspCongrats}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortDNSPCongrats />
                </React.Suspense>
              }
            />
            <Route
              path={ROUTES.short.children.acaCongrats}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeShortACACongrats />
                </React.Suspense>
              }
            />
          </Route>
          <Route path={ROUTES.call.route} element={<Outlet />}>
            <Route
              index
              path={ROUTES.call.children.homePage}
              element={
                <React.Suspense fallback={<Loading />}>
                  <TypeCallCongrats />
                </React.Suspense>
              }
            />
          </Route>

          <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={ROUTES.terms} element={<Terms />} />
          <Route path={ROUTES.partner} element={<Partners />} />
          <Route path={"/"} element={<ModuleTypeNavigator />}></Route>
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default Navigator;
